import React from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import Fade from 'react-reveal/Fade'

import Theme, { Mq } from '../themes/default'
import ContactUsForm from '../molecules/contact-us-form'
import Layout from '../organisms/layout'
import HubSpotFormTag from '../organisms/hubSpotFormTag'
import { SubpageHero } from '../molecules/subpage-hero'
import SEO from '../organisms/seo'

const RequestDemoContainer = styled.div`
  padding: 3rem 5%;
  height: 100%;
  overflow: hidden;

  ${Mq.md} {
    padding: 0 3% 6rem;
    margin: 0 5%;
  }
  ${Mq.xxl} {
    margin: 1% 12% 0;
  }
`

const RequestDemo = () => {
  const onSubmission = () => navigate('/thank-you')

  return (
    <Layout>
      <SEO description="Get a free demo" title="Request Demo" />
      <HubSpotFormTag formId={process.env.GATSBY_HUBSPOT_REQUEST_DEMO_FORM_ID} />
      <SubpageHero title="Request Demo" text="Come see how BiasSync helps companies understand, assess, measure and mitigate unconscious bias." />
      <Fade bottom={true} cascade={true} distance={Theme.fadeDistance}>
        <RequestDemoContainer>
          <ContactUsForm contactType="demo_request" onSubmission={onSubmission} ctaButtonText="Get a free demo" />
        </RequestDemoContainer>
      </Fade>
    </Layout>
  )
}

export default RequestDemo
